import { Providers } from './providers'
import { Router } from './router'
import './scss/main.scss'
import { useEffect } from 'react'
import { Loader } from '@googlemaps/js-api-loader'
import { MixPanel } from './services/mixpanelActions'

declare global {
    interface Window {
        gtag_report_conversion: () => void
        gtag_report_form_submit: () => void
        gtag: (...args: string[]) => void
        hbspt: {
            forms: {
                // eslint-disable-next-line no-empty-pattern
                create: ({
                    portalId,
                    formId,
                    target,
                    onFormSubmit,
                    onFormReady,
                }: {
                    portalId: string | undefined
                    formId: string | undefined
                    target: string
                    onFormSubmit: (() => void) | undefined
                    onFormReady: ((form: HTMLFormElement) => void) | undefined
                }) => void
            }
        }
    }
}

MixPanel.actions.init()

function App() {
    useEffect(() => {
        const loader = new Loader({
            apiKey: process.env.REACT_APP_GOOGLE_MAP_AUTO_COMPLETE_KEY || '',
            version: 'weekly',
            libraries: ['places'],
        })

        loader.load().then(async () => {
            const { Map } = (await google.maps.importLibrary(
                'maps'
            )) as google.maps.MapsLibrary
            const map = new Map(document.getElementById('map') as HTMLElement, {
                center: { lat: -34.397, lng: 150.644 },
                zoom: 8,
            })
        })
    }, [])

    return (
        <Providers>
            <Router />
        </Providers>
    )
}

export default App
